<template>
  <div class="wrapper">
    <div class="back" @click="back">
      <div class="back-icon"></div>
      <div class="back-text">返回</div>
    </div>
    <div class="back-bor"></div>
    <swiper class="swiper" v-if="navList.length > 0" :options="swiperOption">
      <swiper-slide
        class="nav-item"
        v-for="(item, index) in navList"
        :key="index"
        :class="{active: index === active}">
        {{ item.category_name }}
        <div :class="{'active-border': index === active}"></div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { getClassList } from '@/api/Class'

export default {
  name: 'ListenHeader',
  components: {
    Swiper,
    SwiperSlide
  },
  mounted () {
    this._classInit()
  },
  data () {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        freeMode: true,
        slideToClickedSlide: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        on: {
          click: (swiper) => {
            this.active = swiper.clickedIndex
          }
        }
      },
      active: 0,
      navList: [
        {
          category_guid: '',
          category_name: '全部'
        }
      ]
    }
  },
  methods: {
    back () {
      let index = this.$route.path.indexOf('/listen')
      let path = this.$route.path.substring(0, index)
      this.$router.push(path)
    },
    _classInit () {
      getClassList({
        category_type: 2
      }).then(res => {
        if (res.code === 200) {
          this.navList = this.navList.concat(res.data)
        }
      })
    }
  },
  watch: {
    active () {
      this.$emit('changeClass', this.navList[this.active].category_guid)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.wrapper
  display flex
  width 750px
  height 76px

  .back
    display flex
    align-items center
    width 142px
    height 70px
    padding-top 6px

    .back-icon
      width 16px
      height 28px
      margin-left 43px
      bg-image('~@/assets/img/reading/back')
      background-size 100% 100%
      background-repeat no-repeat

    .back-text
      width 74px
      line-height normal
      font-size 26px
      font-weight 500
      color rgba(51, 51, 51, 1)
      margin-left 10px

  .back-bor
    width 1px
    height 40px
    margin-top 22px
    bg-image('~@/assets/img/activity/line')
    background-size 100% 100%
    background-repeat no-repeat

  .swiper
    width 586px
    height 76px
    margin 0
    padding-left 20px

    .nav-item
      width auto
      height 28px
      text-align center
      font-size 28px
      color rgba(51, 51, 51, 1)
      padding 32px 28px 16px 28px

      &.active
        position relative
        top -4px
        font-size 34px
        color rgba(179, 53, 58, 1)
        padding 29px 26px 13px 26px

    .active-border
      width 30px
      height 6px
      background rgba(179, 53, 58, 1)
      border-radius 3px
      margin 10px auto 0 auto
</style>
