<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="listen">
      <listen-header @changeClass="changeClass"></listen-header>
      <scroll
        ref="scroll"
        class="scroll"
        :data="listenList"
        :listen-scroll="true"
        @scroll="scroll"
        :pull-down-refresh="{threshold: 30, stop: 0}"
        @pullingDown="pullDownRefresh"
        :pull-up-load="true"
        @pullingUp="pullUpPush"
      >
        <div>
          <div class="pull-down-wrapper">
            <div class="pull-down-text" v-show="!isPullingDown">下拉即可刷新...</div>
            <div class="pull-down-text" v-show="isPullingDown">释放即可刷新...</div>
          </div>
          <listen-list :listen-list="listenList" :user-guid="userGuid"></listen-list>
          <div class="home-bottom" v-show="showDivider">
            <divider class="divider">已加载全部内容</divider>
          </div>
        </div>
      </scroll>
    </div>
  </transition>
</template>
<script>
import Scroll from '@/components/scroll/scroll'
import ListenHeader from './components/Header'
import ListenList from './components/List'
import { Divider, Toast } from 'vant'
import { getResourcesPageList } from '@/api/Resources'
import { mapGetters } from 'vuex'

export default {
  name: 'Listen',
  components: {
    Scroll,
    ListenHeader,
    ListenList,
    Divider
  },
  computed: {
    ...mapGetters([
      'agencyGuid',
      'userGuid',
      'openid'
    ])
  },
  mounted () {
    this._getResourcesPageList()
  },
  data () {
    return {
      isPullingDown: false,
      showDivider: false,
      categoryGuid: '', // 分类主键
      page: 1,
      limit: 10,
      listenList: []
    }
  },
  methods: {
    _getResourcesPageList () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      getResourcesPageList({
        agency_guid: this.agencyGuid,
        resources_type: [2, 6],
        category_guid: this.categoryGuid,
        page: this.page,
        limit: this.limit
      }).then(res => {
        if (res.code === 200) {
          this.listenList = this.listenList.concat(res.data.list)
          if (res.data.count <= this.listenList.length) {
            this.showDivider = true
          }
        }
        Toast.clear()
      })
    },
    scroll (pos) {
      if (pos.y > 30) {
        this.isPullingDown = true
      } else if (pos.y <= 0) {
        this.isPullingDown = false
      }
    },
    pullDownRefresh () {
      this.page = 1
      this.listenList.splice(0, this.listenList.length)
      this.showDivider = false
      this._getResourcesPageList()
    },
    pullUpPush () {
      if (this.showDivider) {
        return true
      }
      this.page++
      this._getResourcesPageList()
    },
    changeClass (categoryGuid) {
      this.categoryGuid = categoryGuid
      this.page = 1
      this.listenList.splice(0, this.listenList.length)
      this.showDivider = false
      this._getResourcesPageList()
    }
  }
}
</script>

<style lang="stylus" scoped>
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.listen
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)

  .scroll
    position absolute
    top 106px
    left 0
    right 0
    bottom 0
    overflow hidden

    .pull-down-wrapper
      display flex
      justify-content center
      align-items center
      width 750px
      height 70px
      margin-top -70px

      .pull-down-text
        line-height normal
        font-size 22px
        color rgba(153, 153, 153, 1)

    .home-bottom
      wdith 750px
      height 70px
      padding-top 30px

      .divider
        width 300px
        height 22px
        font-size 22px
        color rgba(153, 153, 153, 1)
        margin 0 auto
        border-color rgba(153, 153, 153, 1)
</style>
